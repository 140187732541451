import type { SearchFilters, LocationFilters } from '@/components/SearchFilters/types'
import { getNeighborhoodsFromClustersValues } from '@/utils/locations'
import type { LocationsQueryUtils } from '@/stores/locationsStore/types'

export const formatFilterValue = (value: string | null | string[]) => {
  if (Array.isArray(value)) {
    return value.join('~')
  }

  return value
}

export const parseSearchFilters = (filters: {[key: string]: (string | null)}, countryCode: string, locationsQueryUtils: LocationsQueryUtils): SearchFilters => {
  const hasClustersSelected = !!filters.clusters

  const clusters = hasClustersSelected ? (formatFilterValue(filters.clusters) as string).split('~') : []
  const validNeighborhoodValues = locationsQueryUtils.getAllAvailableNeighborhoods().map(neighborhood => neighborhood.value)

  let businessHubs: string[]
  let neighborhoods: string[]

  if (filters.neighborhoods) {
    neighborhoods = (formatFilterValue(filters.neighborhoods) as string)
      .split('~')
      .filter(neighborhoodValue => validNeighborhoodValues.includes(neighborhoodValue))
    businessHubs = locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(neighborhoods).map(hub => hub.value)
  } else if (hasClustersSelected) {
    neighborhoods = getNeighborhoodsFromClustersValues(clusters, countryCode)
      .map(neighborhood => neighborhood.value)
      .filter(neighborhoodValue => validNeighborhoodValues.includes(neighborhoodValue))
    businessHubs = locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(neighborhoods).map(hub => hub.value)
  } else {
    neighborhoods = []
    businessHubs = []
  }

  if (filters.delivery_term) {
    filters.deliveryTerm = filters.delivery_term
  }

  if (filters.bedrooms_number) {
    filters.bedroomsNumber = filters.bedrooms_number
  }

  let location: LocationFilters | null = null
  const city = filters.city
  const zone = filters.zone
  const neighborhood = filters.neighborhood

  if (city || zone || neighborhood) {
    if (city) {
      location = { city }
      if (zone) location.zone = zone
      if (neighborhood) location.neighborhood = neighborhood
    }
  }

  return {
    businessHubs,
    clusters,
    neighborhoods,
    opportunityType: filters.opportunityType ?? null,
    deliveryTerm: filters.deliveryTerm ?? null,
    bedroomsNumber: parseInt(filters.bedroomsNumber as string) || null,
    price: {
      min: parseInt(filters.price?.split(':')[0] as string) || null,
      max: parseInt(filters.price?.split(':')[1] as string) || null
    },
    area: {
      min: parseInt(filters.area?.split(':')[0] as string) || null,
      max: parseInt(filters.area?.split(':')[1] as string) || null
    },
    q: filters.q ?? null,
    ids: filters.ids?.split('~') ?? [],
    location
  }
}

export const convertSearchFiltersToQueryObject = (filters: SearchFilters) => {
  const query: any = {}

  if (filters.businessHubs?.length) {
    query.business_hubs = filters.businessHubs.join('~')
  }

  if (filters.clusters?.length) {
    query.clusters = filters.clusters.join('~')
  }

  if (filters.neighborhoods?.length) {
    query.neighborhoods = filters.neighborhoods.join('~')
  }

  if (filters.opportunityType) {
    query.opportunity_type = filters.opportunityType
  }

  if (filters.deliveryTerm) {
    query.delivery_term = filters.deliveryTerm
  }

  if (filters.bedroomsNumber) {
    query.bedrooms_number = filters.bedroomsNumber.toString()
  }

  if (filters.bathroomsNumber) {
    query.bathrooms_number = filters.bathroomsNumber.toString()
  }

  if (filters.parkingLotsNumber) {
    query.parking_lots_number = filters.parkingLotsNumber.toString()
  }

  if (filters.price) {
    if (Object.values(filters.price).some(Boolean)) {
      query.price = `${filters.price.min || ''}:${filters.price.max || ''}`
    } else {
      query.price = undefined
    }
  }

  if (filters.area) {
    if (Object.values(filters.area).some(Boolean)) {
      query.area = `${filters.area.min || ''}:${filters.area.max || ''}`
    } else {
      query.area = undefined
    }
  }

  if (filters.q) {
    query.q = filters.q
  }

  if (filters.ids?.length) {
    query.ids = filters.ids.join('~')
  }

  if (filters.location) {
    Object.entries(filters.location).forEach(([key, value]) => {
      query[key] = value
    })
  }

  return query
}

export const extractValidFilters = (filters: SearchFilters) => {
  const validFilters: Partial<SearchFilters> = {}

  if (filters.businessHubs?.length) {
    validFilters.businessHubs = filters.businessHubs
  }

  if (filters.clusters?.length) {
    validFilters.clusters = filters.clusters
  }

  if (filters.neighborhoods?.length) {
    validFilters.neighborhoods = filters.neighborhoods
  }

  if (filters.opportunityType) {
    validFilters.opportunityType = filters.opportunityType
  }

  if (filters.deliveryTerm) {
    validFilters.deliveryTerm = filters.deliveryTerm
  }

  if (filters.bedroomsNumber !== null) {
    validFilters.bedroomsNumber = filters.bedroomsNumber
  }

  return validFilters
}
