export const simplify = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()
}

export const slugify = (str: string) => {
  return simplify(str).replace(/ /g, '-')
}

export const unslugify = (slug: string) => {
  if (!slug) return ''

  return slug
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
