export const isString = (value: string): boolean => {
  return (typeof value === 'string' && value.trim() !== '')
}

export const isNumber = (value: number): boolean => {
  return (typeof value === 'number' && !isNaN(value))
}

export const isBoolean = (value: boolean): boolean => {
  return typeof value === 'boolean'
}
